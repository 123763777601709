<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			row: false,
			terkait: false,
			input: {},
		}
	},
	async mounted() {
		await this.ready();
		this.getDetail()
	},
	methods: {
		getDetail(){
			this.loadingOverlay = true
			this.row = false
			Gen.apirest("/pertanyaan/"+this.$route.params.code, {}, (err, resp)=>{
				this.loadingOverlay = false
				if (resp.code == 404) return this.$router.push({name:'404'})
				this.row = resp.data
			})
		},
		submit() {
			this.loadingOverlay = true
			Gen.apirest("/submit-jawaban", this.row, (err) => {
				this.loadingOverlay = false
				if (err){
					swal("Warning!", err.resp.message, "warning")
					return;
				} 
				swal("Sukses", "Jawaban Anda berhasil terkirim.", "success")
				this.$router.push({name:'Index'})
			}, "POST")
		},
	},
	watch:{
		$route(){
			this.getDetail()
		}
	}
}
</script>
<template>
	<section id="content">
		<div class="content-wrap">
			<section class="section nobg nomargin">
				<div class="container">
					<div class="row justify-content-center" v-if="row">
						<div class="col-md-8">
							<h2 class="text-brown mb-0">Jawab Pertanyaan</h2>
							<div class="question-wrap card">
								<div class="card-body">
									<div class="qw-title">
										<h3>{{row.ad_subject}}</h3>
										<span>Oleh : {{row.ad_fullname}}</span><span>{{row.ad_create_at.dates("format")}}</span>
										<span>{{row.ad_email}}</span>
									</div>
									<div class="qw-content">
										<p>{{row.ad_question}}</p>
									</div>
								</div>
							</div>
							<div class="question-wrap card">
								<div class="card-body">
									<VForm @resp="submit">
										<div class="form-group">
											<label for="docName">Dijawab Oleh</label>
											<input type="text" name="Nama" v-model="row.ad_answerd_by" class="form-control" required>
										</div>
										<div class="form-group">
											<label for="docName">Jawaban</label>
											<BoField name="ad_answer" mandatory>
					                          <CKEditor name="ad_answer" v-model="row.ad_answer"
					                            v-bind="validation('ad_answer')"></CKEditor>
					                        </BoField>
										</div>
										<div class="text-right">
											<input type="submit" value="Kirim Jawaban" class="primary-btn">
										</div>
									</VForm>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>

	</section>
</template>